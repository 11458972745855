<template>
    <div>
        <div class="main-title">广告列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">广告支持多位置展示</li>
                    </ul>
                </div>
            </div>
            <div class="margin-b-sm margin-l-sm">
               <span class="fl-l" aria-label="位置">
                  <el-select v-model="position" clearable placeholder="请选择位置">
               	   <el-option value="1" label="圈子"></el-option>
               	   <el-option value="2" label="书房"></el-option>
                  </el-select>
               </span>
				<span class="fl-l" aria-label="类型">
				   <el-select v-model="type" clearable placeholder="请选择类型">
					   <el-option value="1" label="小程序"></el-option>
					   <el-option value="2" label="H5"></el-option>
				   </el-select>
				</span>
				<span class="fl-l" aria-label="状态">
				   <el-select v-model="status" clearable placeholder="请选择状态">
					   <el-option value="1" label="启用"></el-option>
					   <el-option value="2" label="禁用"></el-option>
				   </el-select>
				</span>
                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
					<el-button type="primary" size="small" @click="addAdv()">添加</el-button>
                </span>

            </div>

            <div class="table-content margin-t-sm">
                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80"
							align="center"
							>
                    </el-table-column>
                    <el-table-column
                        label="位置"
                        property="position"
						width="100"
						align="center"
                    >
					<template slot-scope="scope">
						<div v-if="scope.row.position === 1">圈子</div>
						<div v-else-if="scope.row.position === 2">书房</div>
					</template>
                    </el-table-column>
					<el-table-column
						label="类型"
						property="type"
						width="120"
						align="center"
					>
					<template slot-scope="scope">
						<div v-if="scope.row.type === 1">小程序</div>
						<div v-else-if="scope.row.type === 2">H5</div>
					</template>
					</el-table-column>
					<el-table-column
						label="链接"
						property="link"
						align="center"
					>
					</el-table-column>
					<el-table-column
						label="图片"
					    property="pic"
						width="120"
						align="center"
					>
					<template slot-scope="scope">
						<el-image
						      style="width: 40px; height: 40px"
						      :src="path+scope.row.pic"
						      fit="contain"
							  lazy
							  :preview-src-list="[path+scope.row.pic]"
							  >
					   </el-image>
					</template>
					</el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="200"
							align="center"
							>
                    </el-table-column>
					<el-table-column
					        property="updated_at"
					        label="更新时间"
					        width="200"
							align="center"
							>
					</el-table-column>
					<el-table-column
						label="状态"
					    property="status"
						width="120"
						align="center"
					>
					<template slot-scope="scope">
						<div>
							<el-switch 
							v-model="scope.row.status" 
							:active-value="1" 
							:inactive-value="2" 
							@change="setStatus(scope.row.id,scope.row.status)"
							active-color="#13ce66"
							inactive-color="#ff4949"
							>
							</el-switch>
						</div>
					</template>
					</el-table-column>
                    <el-table-column
                        label="操作员"
                        property="user.name"
                        width="200"
						align="center"
                    >
                    </el-table-column>
                    <el-table-column
                            width="300"
                            label="操作">
                        <template slot-scope="scope">
							<span class="margin-r-sm">
								<el-link slot="reference" type="primary" @click="editAdv(scope.row.id)">编辑</el-link>
							</span>
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row.id)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch margin-l-sm" >
                <span class="fl-l" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
    export default {
        name: "AdvList",
        data(){
            return {
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                position: '',
				type: '',
				status: '',
                tableData: [],
                currentRow: null,
				importing:false,
				path: config.imageUrl
            }
        },
      created() {
          this.getList();
      },
      methods:{
            ...mapActions('adv',['getAdvList','delAdv','updateAdvStatus']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            // 获得列表
            async getList(){
              this.loading = true
              var vm = this
			  var options = { page: vm.page, pageSize: vm.pageSize }
			  if(vm.position !== ''){
				  options.position = vm.position
			  }
			  if(vm.type !== '') {
				  options.type = vm.type
			  }
			  if(vm.status !== '') {
				  options.status = vm.status
			  }
              const { res_info, data } = await this.getAdvList(options);
              this.loading = false

              if (res_info !== 'ok') return

              this.total = data.total
              this.tableData = data.list
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            // 预览
            showDetail(id){
              this.$router.push('/adv/detail/'+id)
            },
           // 删除
            async deleted(id){
              const { res_info, data } = await this.delAdv(id)
              if (res_info !== 'ok') return
              this.$message.success('删除成功!')
              await this.getList()
            },
			// 添加
			addAdv(){
				this.$router.push('/adv/add')
			},
			// 编辑
			editAdv(id){
				this.$router.push('/adv/edit/'+id)
			},
			async setStatus(id,status){
				const { res_info ,data } = await this.updateAdvStatus({id:id,status:status})
				if(res_info !== 'ok'){
					this.$message.error('操作失败!')
					return false
				}
				this.$message.success('操作成功!')
			}
        }
    }
</script>
